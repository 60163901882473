import './App.css';

const YoutubeEmbed = ({embedId, start}) => (
    <div>
      {start !== null ? (
          <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${embedId}?start=${start}`}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
      ): (
          <iframe width="560" height="315" src={`https://www.youtube-nocookie.com/embed/${embedId}`}
                  title="YouTube video player" frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen></iframe>
      )}
    </div>
);

const VimeoEmbed = ({embedId}) => (
    <div className="video-responsive">
      <iframe src={`https://player.vimeo.com/video/${embedId}?h=c66a845354&color=ffffff&title=0&byline=0&portrait=0`}
              width="640" height="360" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"
              allowFullScreen></iframe>
    </div>
);

function App() {
  return (
      <div>
        <YoutubeEmbed embedId="JV3KOJ_Z4Vs" />
        <YoutubeEmbed embedId="ZXsQAXx_ao0" />
        <YoutubeEmbed embedId="L1Bsipw7T-E" start="962" />
        <VimeoEmbed embedId="125095515" title="#INTRODUCTIONS (2015)"/>
      </div>
  );
}

export default App;
